body {
  margin: 0;
  font-family: "Times New Roman", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    'Karla', 'Quicksand', 'Work Sans', 'Muli', 'Raleway',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Circular";
  src: local("Circular"), url(/static/media/CircularStd-Book.6365c40a.otf) format("opentype");
}

@font-face {
  font-family: "Circular Light";
  src: local("Circular Light"), url(/static/media/CircularStd-Light.0d87e691.otf) format("opentype");
}

*, *:after, *:before { box-sizing: border-box; }

/* skdfjhskjfh */

body {
	background: #fff;
	color: #777;
	font-weight: 400;
	font-size: 1em;
	font-family: 'Lato', Arial, sans-serif;
}

.clearfix:before,
.clearfix:after {
	content: '';
	display: table;
}

.clearfix:after {
	clear: both;
}

a {
	color: #f4726d;
	text-decoration: none;
	outline: none;
}

a:hover, a:focus {
	color: #c44d48;
}

.codrops-header {
	margin: 0 auto;
	padding: 4.5em 2em;
	text-align: center;
}

.codrops-header h1 {
	margin: 0;
	font-weight: 300;
	font-size: 2.5em;
	line-height: 1.3;
}

.codrops-header h1 span {
	display: block;
	padding: 0 0 0.6em 0.1em;
	font-size: 60%;
	opacity: 0.7;
}

/* To Navigation Style */
.codrops-top {
	width: 100%;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 0.69em;
	line-height: 2.2;
	background: #fff;
}

.codrops-top a {
	display: inline-block;
	padding: 0 1em;
	text-decoration: none;
	letter-spacing: 1px;
}

.codrops-top span.right {
	float: right;
}

.codrops-top span.right a {
	display: block;
	float: left;
}

.codrops-icon:before {
	margin: 0 4px;
	text-transform: none;
	font-weight: normal;
	font-style: normal;
	-webkit-font-feature-settings: normal;
	        font-feature-settings: normal;
	font-variant: normal;
	font-family: 'codropsicons';
	line-height: 1;
	speak: none;
	-webkit-font-smoothing: antialiased;
}

.codrops-icon-drop:before {
	content: "\E001";
}

.codrops-icon-prev:before {
	content: "\E004";
}

.related {
	text-align: center;
	font-size: 1.25em;
	padding: 3em 0;
	overflow: hidden;
}

.related a {
	display: inline-block;
	text-align: left;
	margin: 20px auto;
	padding: 10px 20px;
	opacity: 0.8;
	color: #777;
	transition: opacity 0.3s;
	-webkit-backface-visibility: hidden;
}

.related a:hover,
.related a:active {
	opacity: 1;
}

.related a img {
	max-width: 100%;
}

.related a h3 {
	font-weight: 300;
	margin: 0;
	padding: 0.3em 0;
}

.wrap {
	background-color: #f4726d
}

.wrap > div {
	float: left;
	overflow: hidden;
	padding: 0 0 8em;
	height: 275px;
	width: 50%;
	text-align: center;
}

.wrap > div h2 {
	text-transform: uppercase;
	letter-spacing: 1px;
	margin: 0;
	padding: 4em 0 5em;
	font-size: 0.85em;
	color: #c44d48;
}

.dotstyle:nth-child(4n),
.dotstyle:nth-child(4n-3) {
	background-color: rgba(0, 0, 0, 0.05);
}

@media screen and (max-width: 42em) {
	.wrap > div {
		float: none;
		width: 100%;
	}

	.dotstyle:nth-child(4n),
	.dotstyle:nth-child(4n-3) {
		background-color: rgba(0, 0, 0, 0);
	}

	.dotstyle:nth-child(odd) {
		background-color: rgba(0, 0, 0, 0.05);
	}
}

@media screen and (max-width: 25em) {

	.codrops-icon span {
		display: none;
	}

	.codrops-icon:before {
		font-size: 160%;
		line-height: 2;
	}

}

/* Common styles and resets */

.dotstyle ul {
	position: relative;
	display: inline-block;
	margin: 0;
	padding: 0;
	list-style: none;
	cursor: default;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.dotstyle li {
	position: relative;
	display: block;
	float: left;
	margin: 0 16px;
	width: 16px;
	height: 16px;
	cursor: pointer;
}

.dotstyle li a {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	outline: none;
	border-radius: 50%;
	background-color: #fff;
	background-color: rgba(255, 255, 255, 0.3);
	text-indent: -999em; /* make the text accessible to screen readers */
	cursor: pointer;
	position: absolute;
}

.dotstyle li a:focus {
	outline: none;
}

.myDiv{
  height:200px;
  width:200px;
  background:red;
  transition: width 2s, height, 2s, background 2s;
}
.myDiv:hover{
  height:300px;
  width:300px;
  background:green
}
article{
  -webkit-column-count:6;
          column-count:6;
}

article h1{
  -webkit-column-span:all;
          column-span:all;
}

.dotstyle-tooltip li {
	border: 2px solid #fff;
	border-radius: 50%;
	z-index: 1;
	transition: border-color 0.3s ease;
}

.dotstyle-tooltip li a {
	top: auto;
	bottom: 250%;
	left: 50%;
	visibility: hidden;
	padding: 0 10px;
	width: auto;
	height: auto;
	border-radius: 0;
	background-color: #c44d48;
	color: #fff;
	text-indent: 0;
	line-height: 2;
	opacity: 0;
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	transition: opacity 0.3s ease, visibility 0s 0.3s ease, -webkit-transform 0.3s ease;
	transition: transform 0.3s ease, opacity 0.3s ease, visibility 0s 0.3s ease;
	transition: transform 0.3s ease, opacity 0.3s ease, visibility 0s 0.3s ease, -webkit-transform 0.3s ease;
}

.dotstyle-tooltip li a::after { /* http: //cssarrowplease.com/ */
	position: absolute;
	left: 50%;
	margin-left: -10px;
	width: 0;
	height: 0;
	border: solid transparent;
	border-width: 10px;
	border-color: transparent;
	border-top-color: #c44d48;
	content: '';
	pointer-events: none;
	top: 99%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	transition: opacity 0.3s ease;
}

.no-touch .dotstyle-tooltip li:hover a,
.dotstyle-tooltip li.current a {
	z-index: 100;
	visibility: visible;
	opacity: 1;
	-webkit-transform: translateX(-50%) translateY(0%);
	transform: translateX(-50%) translateY(0%);
	transition: opacity 0.3s ease, -webkit-transform 0.3s ease;
	transition: transform 0.3s ease, opacity 0.3s ease;
	transition: transform 0.3s ease, opacity 0.3s ease, -webkit-transform 0.3s ease;
}

.dotstyle-tooltip li.current {
	border-color: #c44d48;
}

.dotstyle-tooltip li:hover {
	z-index: 100;
}

.no-touch .dotstyle-tooltip ul:hover li.current a {
	opacity: 0.2;
}

.dotstyle-tooltip ul li.current:hover a {
	opacity: 1;
}



.header {
  box-shadow: 1px 1px 1px 0 rgba(61, 61, 61, 0.2);
  width: 100%;
  z-index: 3;
  top: 0;
}

.header ul {
  margin: 0;
  padding: 0 0 60px 0;
  list-style: none;
  overflow: hidden;
}

.header li a {
  display: block;
  padding: 20px 0;
  text-decoration: none;
}


.header .logo {
  display: block;
  float: left;
  font-size: 2em;
  padding: 10px 20px 0 56px;
  text-decoration: none;
}

/* menu */

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height .4s ease-out;
  padding: 0 40px 0 0;
}

/* menu icon */

.header .menu-icon-darkMode {
  cursor: pointer;
  display: inline-block;
  float: left;
  padding: 28px 20px;
  position: relative;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.header .menu-icon-darkMode .navicon {
  display: block;
  height: 1px;
  position: relative;
  transition: background .2s ease-out;
  width: 22px;
}

.header .menu-icon-darkMode .navicon:before,
.header .menu-icon-darkMode .navicon:after {
  background: #eee;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.header .menu-icon-darkMode .navicon:before {
  top: 5px;
}

.header .menu-icon-darkMode .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 300px;
}

.header .menu-btn:checked ~ .menu-icon-darkMode .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon-darkMode .navicon:before {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon-darkMode .navicon:after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon-darkMode:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon-darkMode:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 48em) {
  .header li {
    float: left;
  }
  .header .menu {
    clear: none;
    float: left;
    max-height: none;
  }
  .header .menu-icon-darkMode {
    display: none;
  }
}


.header .menu-icon-lightMode {
  cursor: pointer;
  display: inline-block;
  float: left;
  padding: 28px 20px;
  position: relative;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.header .menu-icon-lightMode .navicon {
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 22px;
}

.header .menu-icon-lightMode .navicon:before,
.header .menu-icon-lightMode .navicon:after {
  background: #111;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.header .menu-icon-lightMode .navicon:before {
  top: 5px;
}

.header .menu-icon-lightMode .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 300px;
}

.header .menu-btn:checked ~ .menu-icon-lightMode .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon-lightMode .navicon:before {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon-lightMode .navicon:after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon-lightMode:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon-lightMode:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 48em) {
  .header li {
    float: left;
  }
  .header .menu {
    clear: none;
    float: left;
    max-height: none;
  }
  .header .menu-icon-lightMode {
    display: none;
  }
}


.nav-link {
	color: grey;
}

#nav-link-light {
	color: grey;
	transition: color .5s;
}

#nav-link-dark {
	color: grey;
	transition: color .5s;
}

#nav-link-light:hover {
	color: black;
	transition: color .5s;
}

#nav-link-dark:hover{
	color: #eeeeee;
	transition: color .5s;
}

/* light mode - email button */
#email_btn {
    color: black;
    border: 1px solid #c9c9c9;
    /* transition: 0.3s; */
    display: inline-block;
    padding: 10px 15px 10px 25px;
    border-radius: 30px;
    width: 220px;
    transition: width 0.2s;
  }
  
#email_btn:hover {
    /* background-color: black; */
    border: 1px solid black;
    /* color: white; */
    opacity: 1;
    width: 280px;
    /* transition: 1s; */
}

#email_image{
    height: 35px;
    width: 35px;
    margin: 0 0 0 10px;
    /* background: url('./resources/images/imail_circle.png'); */
    background: url(/static/media/black_arrow.c83fdd1f.png);
    background-size:     cover;  
    background-repeat:   no-repeat;
    /* display: inline-block; */
    /* vertical-align: middle; */
    transition: 0.6s;
    float: right;
}

#email_btn:hover > #email_image {
    transition: 0.5s;
    /* background: url('./resources/images/imail_circle.png'); */
    background: url(/static/media/white_arrow.2b6901cb.png);
    background-size:     cover;    
    background-repeat:   no-repeat;
    /* border: 1px solid black;
    border-radius: 30px; */
    /* display: inline-block; */
}

#email_label {
    float: left;
    margin: 4px 0 0 0;
}

#email_btn:hover > #email_label {
    /* display: none; */
    opacity: 0;
    /* color: grey; */
    margin: -40000px 0 0 0;
    transition: margin .1s;
    transition: opacity .1s;
}

#email_reveal {
    opacity: 0;
    float: left;
    margin: 10px 0 0 -50px;
    /* display: none; */
    position: absolute;
    z-index:100;
}

#email_btn:hover > #email_reveal {
    /* display: inline; */
    opacity: 1;
    margin: 4px 0 0 0;
    transition: margin .4s;
    transition: opacity .4s;
}


/* light mode - linkedin button */

#linkedin_btn {
    color: black;
    border: 1px solid #c9c9c9;
    transition: 0.3s;
    display: inline-block;
    padding: 10px 15px 10px 25px;
    border-radius: 30px;
    width: 220px;
    transition: width 0.2s;
  }
  
#linkedin_btn:hover {
    border: 1px solid black;
    opacity: 1;
    width: 280px;
}

#linkedin_image{
    height: 35px;
    width: 35px;
    margin: 0 0 0 10px;
    /* background: url('./resources/images/linkedin_circle.png'); */
    background: url(/static/media/black_arrow.c83fdd1f.png);
    background-size:     cover;  
    background-repeat:   no-repeat;
    transition: 0.6s;
    float: right;
}

#linkedin_btn:hover > #linkedin_image {
    transition: 0.5s;
    /* background: url('./resources/images/linkedin_logo.png'); */
    background: url(/static/media/white_arrow.2b6901cb.png);
    background-size:     cover;    
    background-repeat:   no-repeat;
}

#linkedin_label {
    float: left;
    margin: 4px 0 0 0;
}

#linkedin_btn:hover > #linkedin_label {
    /* display: none; */
    opacity: 0;
    margin: -40000px 0 0 0;
    transition: margin .1s;
    transition: opacity .1s;
}

#linkedin_reveal {
    /* display: none; */
    opacity: 0;
    margin: 10px 0 0 -50px;
    position: absolute;
    z-index: 100;
}

#linkedin_btn:hover > #linkedin_reveal {
    /* display: inline; */
    opacity: 1;
    margin: 4px 0 0 0;
    transition: margin .4s;
    transition: opacity .4s;
}



/* light mode - github button */

#github_btn {
    color: black;
    border: 1px solid #c9c9c9;
    transition: 0.3s;
    display: inline-block;
    padding: 10px 15px 10px 25px;
    border-radius: 30px;
    width: 220px;
    transition: width 0.2s;
  }
  
#github_btn:hover {
    /* background-color: black; */
    border: 1px solid black;
    opacity: 1;
    width: 280px;
}

#github_image{
    height: 35px;
    width: 35px;
    margin: 0 0 0 10px;
    /* background: url('./resources/images/github_dark.png'); */
    background: url(/static/media/black_arrow.c83fdd1f.png);
    background-size:     cover;  
    background-repeat:   no-repeat;
    transition: 0.6s;
    float: right;
}

#github_btn:hover > #github_image {
    transition: 0.5s;
    /* background: url('./resources/images/github_light.png'); */
    background: url(/static/media/white_arrow.2b6901cb.png);
    background-size:     cover;    
    background-repeat:   no-repeat;
}

#github_label {
    float: left;
    margin: 4px 0 0 0;
}

#github_btn:hover > #github_label {
    /* display: none; */
    opacity: 1;
    margin: -40000px 0 0 0;
    transition: margin .1s;
    transition: opacity .1s;
}

#github_reveal {
    /* display: none; */
    opacity: 0;
    float: left;
    margin: 10px 0 0 -50px;
    position: absolute;
    z-index: 100;
}

#github_btn:hover > #github_reveal {
    /* display: inline; */
    opacity: 1;
    margin: 4px 0 0 0;
    transition: margin .4s;
    transition: opacity .4s;
}


/* ------ DARK MODE -------- */

/* dark mode - email button */
#email_btn_dark {
    color: #eeeeee;
    border: 1px solid #3d3d3d;
    /* transition: 0.3s; */
    display: inline-block;
    padding: 10px 15px 10px 25px;
    border-radius: 30px;
    width: 220px;
    transition: width 0.2s;
  }
  
#email_btn_dark:hover {
    /* background-color: black; */
    border: 1px solid #828181;
    /* color: white; */
    opacity: 1;
    width: 280px;
    /* transition: 1s; */
}

#email_image_dark {
    height: 35px;
    width: 35px;
    margin: 0 0 0 10px;
    /* background: url('./resources/images/imail_circle.png'); */
    background: url(/static/media/grey_arrow.d97936e7.png);
    background-size:     cover;  
    background-repeat:   no-repeat;
    /* display: inline-block; */
    /* vertical-align: middle; */
    transition: 0.2s;
    float: right;
}

#email_btn_dark:hover > #email_image_dark {
    transition: 0.3s;
    /* background: url('./resources/images/imail_circle.png'); */
    background: url(/static/media/white_arrow.2b6901cb.png);
    background-size:     cover;    
    background-repeat:   no-repeat;
    /* border: 1px solid black;
    border-radius: 30px; */
    /* display: inline-block; */
}

#email_label_dark {
    float: left;
    margin: 4px 0 0 0;
}

#email_btn_dark:hover > #email_label_dark {
    /* display: none; */
    opacity: 0;
    /* color: grey; */
    margin: -40000px 0 0 0;
    transition: margin .1s;
    transition: opacity .1s;
}

#email_reveal_dark {
    opacity: 0;
    float: left;
    margin: 10px 0 0 -50px;
    /* display: none; */
    position: absolute;
    z-index:100;
}

#email_btn_dark:hover > #email_reveal_dark {
    /* display: inline; */
    opacity: 1;
    margin: 4px 0 0 0;
    transition: margin .4s;
    transition: opacity .4s;
}


/* dark mode - linkedin button */

#linkedin_btn_dark {
    color: #eeeeee;
    border: 1px solid #3d3d3d;
    /* transition: 0.3s; */
    display: inline-block;
    padding: 10px 15px 10px 25px;
    border-radius: 30px;
    width: 220px;
    transition: width 0.2s;
  }
  
#linkedin_btn_dark:hover {
    border: 1px solid #828181;
    opacity: 1;
    width: 280px;
}

#linkedin_image_dark{
    height: 35px;
    width: 35px;
    margin: 0 0 0 10px;
    /* background: url('./resources/images/linkedin_circle.png'); */
    background: url(/static/media/grey_arrow.d97936e7.png);
    background-size:     cover;  
    background-repeat:   no-repeat;
    transition: 0.2s;
    float: right;
}

#linkedin_btn_dark:hover > #linkedin_image_dark {
    transition: 0.3s;
    /* background: url('./resources/images/linkedin_logo.png'); */
    background: url(/static/media/white_arrow.2b6901cb.png);
    background-size:     cover;    
    background-repeat:   no-repeat;
}

#linkedin_label_dark {
    float: left;
    margin: 4px 0 0 0;
}

#linkedin_btn_dark:hover > #linkedin_label_dark {
    /* display: none; */
    opacity: 0;
    margin: -40000px 0 0 0;
    transition: margin .1s;
    transition: opacity .1s;
}

#linkedin_reveal_dark {
    /* display: none; */
    opacity: 0;
    float: left;
    margin: 10px 0 0 -50px;
    position: absolute;
    z-index: 100;
}

#linkedin_btn_dark:hover > #linkedin_reveal_dark {
    /* display: inline; */
    opacity: 1;
    margin: 4px 0 0 0;
    transition: margin .4s;
    transition: opacity .4s;
}



/* dark mode - github button */

#github_btn_dark {
    color: #eeeeee;
    border: 1px solid #3d3d3d;
    /* transition: 0.3s; */
    display: inline-block;
    padding: 10px 15px 10px 25px;
    border-radius: 30px;
    width: 220px;
    transition: width 0.2s;
  }
  
#github_btn_dark:hover {
    /* background-color: black; */
    border: 1px solid #828181;
    opacity: 1;
    width: 280px;
}

#github_image_dark {
    height: 35px;
    width: 35px;
    margin: 0 0 0 10px;
    /* background: url('./resources/images/github_dark.png'); */
    background: url(/static/media/grey_arrow.d97936e7.png);
    background-size:     cover;  
    background-repeat:   no-repeat;
    transition: 0.2s;
    float: right;
}

#github_btn_dark:hover > #github_image_dark {
    transition: 0.3s;
    /* background: url('./resources/images/github_light.png'); */
    background: url(/static/media/white_arrow.2b6901cb.png);
    background-size:     cover;    
    background-repeat:   no-repeat;
}

#github_label_dark {
    float: left;
    margin: 4px 0 0 0;
}

#github_btn_dark:hover > #github_label_dark {
    /* display: none; */
    opacity: 1;
    margin: -40000px 0 0 0;
    transition: margin .1s;
    transition: opacity .1s;
}

#github_reveal_dark {
    /* display: none; */
    opacity: 0;
    float: left;
    margin: 10px 0 0 -50px;
    position: absolute;
    z-index: 100;
}

#github_btn_dark:hover > #github_reveal_dark {
    /* display: inline; */
    opacity: 1;
    margin: 4px 0 0 0;
    transition: margin .4s;
    transition: opacity .4s;
}



/* Global */
/* html{
    min-height: 100%;
    overflow: hidden;
}
body{
height: calc(100vh - 8em);
padding: 4em;
color: rgba(255,255,255,.75);
font-family: 'Anonymous Pro', monospace;  
background-color: rgb(25,25,25);  
} */
.line-1{
    position: relative;
    top: 50%;  
    width: 24em;
    margin: 0 auto;
    border-right: 2px solid rgba(255,255,255,.75);
    font-size: 180%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);    
}

/* Animation */
.anim-typewriter{
-webkit-animation: typewriter 4s steps(44) 1s 1 normal both,
            blinkTextCursor 500ms steps(44) infinite normal;
        animation: typewriter 4s steps(44) 1s 1 normal both,
            blinkTextCursor 500ms steps(44) infinite normal;
}
@-webkit-keyframes typewriter{
from{width: 0;}
to{width: 24em;}
}
@keyframes typewriter{
from{width: 0;}
to{width: 24em;}
}
@-webkit-keyframes blinkTextCursor{
from{border-right-color: rgba(255,255,255,.75);}
to{border-right-color: transparent;}
}
@keyframes blinkTextCursor{
from{border-right-color: rgba(255,255,255,.75);}
to{border-right-color: transparent;}
}
.mobileUL {
  list-style-type: none;
  padding-left:0;
  width:50%;
}

.mobileUL li {
  font-size: 4vw;
  color: gray
}

.carousel-control-prev-icon {
  height: 60px;
  width: 80px;
  background-image: url(/static/media/back.f06202c0.png);
}

.carousel-control-next-icon {
  height: 60px;
  width: 80px;
  background-image: url(/static/media/next.63499fce.png);
}

.carousel-inner img {
  padding-bottom: 45px;
}

.carousel-indicators li {
    background-color: #a3a3a3;
}
.carousel-indicators .active {
    background-color: #999999;
}

.fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms linear 150ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
}

