body {
  margin: 0;
  font-family: "Times New Roman", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    'Karla', 'Quicksand', 'Work Sans', 'Muli', 'Raleway',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Circular";
  src: local("Circular"), url("./resources/fonts/CircularStd-Book.otf") format("opentype");
}

@font-face {
  font-family: "Circular Light";
  src: local("Circular Light"), url("./resources/fonts/CircularStd-Light.otf") format("opentype");
}
